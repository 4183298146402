import "./footer.css"
import {Col, Container, Row} from "react-bootstrap";

const Footer = () => (
    <footer className="footer bg-dark">
        <Container>
            <Row className="align-items-center">
                <Col lg={6} md={6} sm={6} className="footer-left">
                    <div className="text-center text-sm-left text-md-left">
                        <a href="tel:+31 (0) 297 - 286884">Tel: 0297 - 286884</a> / <a
                        href="mailto:info@ibsgraphics.nl">info@ibsgraphics.nl</a></div>
                    <div className="text-center text-sm-left text-md-left">
                        Groot Mijdrechtstraat 24, 3641RW Mijdrecht
                    </div>

                </Col>
                <Col lg={6} md={6} sm={6} className="footer-right">
                    <div className="text-center text-sm-right text-md-right">Meer info? Zie onze site!</div>
                    <div className="text-center text-sm-right text-md-right"><a
                        href="https://www.ibsgraphics.nl">www.IBSGraphics.nl</a></div>

                </Col>
            </Row>
        </Container>

    </footer>
);

export default Footer