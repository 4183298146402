import axios from '../api/axios';
import useAuth from './useAuth';
import {ROLES} from "../App";
import {useDispatch} from "react-redux";
import {getLoggedInUser} from "../components/user/userSlice";


const useRefreshToken = () => {
    const {setAuth} = useAuth();
    const dispatch = useDispatch()

    function mapToRoles(elem) {
        if (elem.name === "ADMIN")
            return ROLES.ADMIN;
        else if (elem.name === "SUPERVISOR")
            return ROLES.SUPERVISOR;
        else if (elem.name === "EMPLOYEE")
            return ROLES.EMPLOYEE;
        return ROLES.EMPLOYEE
    }

    const refresh = async () => {
        const response = await axios.post('/token/refresh/', {}, {
            withCredentials: true
        });
        const accessToken = response?.data?.access;

        const roles = [ROLES.EMPLOYEE]
        setAuth(prev => {
            return {
                ...prev,
                roles: roles,
                accessToken: accessToken
            }
        });
        dispatch(getLoggedInUser(accessToken))

        return accessToken;
    }
    return refresh;
};

export default useRefreshToken;
