import React, {useEffect, useState} from "react";
import {Badge, Button, Col, Image, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {loggedInUser} from "../user/userSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {addProductItemToBasket} from "../basketitems/basketItemsSlice";
import DeviceIdentifier from 'react-device-identifier';
import {dispatchSimpleErrorMessage} from "../message/messageCodes";
import NumericInput from 'react-numeric-input';


const ProductItem = (props) => {

    const dispatch = useDispatch();
    const axios = useAxiosPrivate()
    const user = useSelector(loggedInUser)
    const {productitem, stock, totalInBasket} = props;
    const [spinnerQuantity, setSpinnerQuantity] = useState(0)
    const [stockLoading, setStockLoading] = useState(true)
    const [addedToBasket, setAddedToBasket] = useState(false)


    const changeQuantity = (event) => {
        setSpinnerQuantity(event)
        setAddedToBasket(false)
    }
    let isMounted = true
    useEffect(() => {
        stock != null ? setStockLoading(false) : setStockLoading(true)
        return () => {
            isMounted = false;
        }
    }, [stock])

    const addItemToBasket = (event) => {
        if (spinnerQuantity % 25 !== 0) {
            dispatchSimpleErrorMessage(dispatch, "Het aantal is geen geldig nummer")
            return
        }
        const basketItem = {quantity: spinnerQuantity, product_item: productitem.id, user: user.id};
        dispatch(addProductItemToBasket({axios, basketItem, productitem}));
        setSpinnerQuantity(0)
    }

    return (
        <>
            <DeviceIdentifier isDesktop={true}>
                <Row className="list-row">
                    <Col md={2} className="text-center align-self-center align-items-center ">
                        {/*<Image className="item-image" src={productitem.image}/>*/}
                        <Image src={productitem.image} className="item-image" onError={({currentTarget}) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "img/404.png";
                        }}/>

                    </Col>
                    <Col md={5} className="d-flex align-self-center align-items-center">{productitem.description}</Col>
                    <Col md={2} className="text-center align-self-center">
                        {stockLoading ? <div className="spinner-grow spinner-grow-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> : stock}
                    </Col>
                    <Col md={2} className="text-center align-self-center align-items-center">
                        <NumericInput
                            mobile
                            className="form-control"
                            value={spinnerQuantity}
                            min={0}
                            max={1000000}
                            step={25}
                            precision={0}
                            size={5}
                            onChange={changeQuantity}
                        />
                    </Col>

                    <Col md={1} className="text-right align-self-center">
                        <div className="basket-group">
                            {/*<Button variant="primary"*/}
                            {/*        disabled={basketItemsFromState && basketItemsFromState.length === 0}*/}
                            {/*        onClick={() => navigate('/basket', {*/}
                            {/*            state: {from: location},*/}
                            {/*            replace: true*/}
                            {/*        })}>*/}
                            {/*     <img className="add-to-card-img" src="img/add-to-cart.png"/> <Badge*/}
                            {/*    bg="white" text="dark">{totalInBasket}</Badge>*/}
                            {/*</Button>*/}

                            <Button
                                disabled={spinnerQuantity === 0 || addedToBasket}
                                onClick={addItemToBasket}
                                className="basket-button">
                                <img className="add-to-card-img" src="img/add-to-cart.png"/> <Badge
                                bg="white" text="dark">{totalInBasket}</Badge>

                            </Button>
                            {/*<div className="in-basket">{totalInBasket}</div>*/}
                        </div>
                    </Col>

                </Row>
                <Row className="item-bottom-row">&nbsp;</Row>

            </DeviceIdentifier>
            <DeviceIdentifier isMobile={true} isTablet={true}>
                <div className="mobile-product-item p-2 mb-4">
                    <Row>
                        <Col>
                            <Row className="product-item-mobile-row-top">
                                &nbsp;
                            </Row>

                            <Row className="product-item-mobile-row">
                                <Col className="text-right align-self-top">{productitem.description}</Col>
                            </Row>
                            <Row className="product-item-mobile-row-image">
                                <Col className="text-center align-self-top">
                                    <Image src={productitem.image} className="item-image"
                                                   onError={({currentTarget}) => {
                                                       currentTarget.onerror = null; // prevents looping
                                                       currentTarget.src = "img/404.png";
                                                   }}/>
                                </Col>
                            </Row>
                            <Row className="product-item-mobile-row">
                                <Col xs={4} className="text-left align-self-top"><strong>Voorraad</strong></Col>
                                <Col xs={8} className="text-right align-self-top">{stockLoading ?
                                    <div className="align-self-center spinner-grow spinner-grow-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : stock > 0 ? stock : "In productie"}</Col>
                            </Row>
                            <Row className="product-item-mobile-row">
                                <Col xs={4} className="text-left align-self-center"><strong>Aantal</strong></Col>

                                <Col xs={8} className="text-right align-self-center">
                                    <NumericInput
                                        mobile
                                        className="form-control"
                                        value={spinnerQuantity}
                                        min={0}
                                        max={1000000}
                                        step={25}
                                        precision={0}
                                        size={5}
                                        onChange={changeQuantity}
                                    />


                                </Col>
                            </Row>


                            <Row className="product-item-mobile-row">
                                <Col xs={6} className="text-left align-self-center"><strong>In mandje</strong></Col>
                                <Col xs={6} className="text-right align-self-center">{totalInBasket}</Col>
                            </Row>
                            <Row className="product-item-mobile-row text-center">
                                <Col xs="12" className="text-center">
                                    <Button disabled={spinnerQuantity === 0 || addedToBasket} onClick={addItemToBasket}
                                            className="basket-button text-center">
                                        Plaats in winkelmand
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="product-item-mobile-row-bottom"></Row>
                        </Col>
                    </Row>
                </div>
            </DeviceIdentifier>
        </>
    );

}


export default ProductItem
