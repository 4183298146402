import {useDispatch, useSelector} from "react-redux";

import {messageCode, messageShown, messageType, newMessage, popupMessage, setMessageShown} from "./messageSlice";
import {Col, Row} from "react-bootstrap";
import {useEffect} from "react";
import "./messages.css"
import {toast, ToastContainer} from "react-toastify";
import {ERROR, INFO, SUCCESS} from "./messageCodes";


const ToastMessage = (props) => {
    const message = useSelector(popupMessage);
    const code = useSelector(messageCode);
    const type = useSelector(messageType);
    const hasMessage = useSelector(newMessage);
    const dispatch = useDispatch()
    const messageAlreadyShown = useSelector(messageShown)

    useEffect(() => {
        const messageOptions = {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            newestOnTop: true,
            progress: undefined
        }
        if (hasMessage && !messageAlreadyShown) {
            if (type === ERROR) {
                toast.error(message, messageOptions);
            } else if (type === SUCCESS) {
                toast.success(message, messageOptions);
            } else if (type === INFO) {
                toast.info(message, messageOptions);
            }
            dispatch(setMessageShown())

        }
    }, [hasMessage]);


    return (
        <>
            {
                message ? <Row key="message">
                    <Col md={12} className="error-message">
                        <ToastContainer/>
                    </Col>
                </Row> : <></>
            }
        </>
    )
}

export default ToastMessage;