export const DELIVERED = "Geleverd"
export const BACKORDER = "Backorder"
export const IN_PROGRESS = "In bewerking"
export const NOT_AVAILABLE = "Niet beschikbaar"



export const translateStatus = (status) => {
    if (status.toLowerCase() === "geleverd") {
        return DELIVERED
    } else if (status.toLowerCase() === "backorder") {
        return BACKORDER
    } else if (status.toLowerCase() === "order") {
        return IN_PROGRESS
    }
    return DELIVERED
}

