import React from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import "./Productgroups.css";
import {useDispatch, useSelector} from "react-redux";
import {productGroups, setSelectedProductGroup} from "./productGroupSlice";
import {useLocation, useNavigate} from "react-router-dom";

const ProductGroupImageList = (props) => {
    const productGroupList = useSelector(productGroups);
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const clickCard = (id) => {
        dispatch(setSelectedProductGroup(id))
        navigate('/seasons', {state: {from: location}, replace: true})
    }

    return (
        <Container className="mt-4">
            <Row>
                {/*<Col key={"product-group-image-col"+0} className="col-md-3 col-xs-12 col-sm-6 season-card" >*/}
                {/*        <Card key={"product-group-image-"+0} onClick={() => clickCard(0)}>*/}
                {/*            <Card.Img variant="top" src={"productgroups/" + 0 + ".png"}/>*/}
                {/*            <Card.Body>*/}
                {/*                <Card.Title className="card-title">Alle Collecties</Card.Title>*/}

                {/*            </Card.Body>*/}
                {/*        </Card>*/}
                {/*    </Col>*/}
                {productGroupList ? productGroupList.map(productGroup => {
                    return <Col key={"product-group-image-col"+productGroup.id} className="col-md-3 col-xs-12 col-sm-6 season-card" >
                        <Card key={"product-group-image-"+productGroup.id} onClick={() => clickCard(productGroup.id)}>
                            <Card.Img variant="top" src={productGroup.image}/>
                            <Card.Body>
                                <Card.Title className="card-title">{productGroup.name}</Card.Title>

                            </Card.Body>
                        </Card>
                    </Col>

                }): <Col md={12}>Geen seizoenen gevonden</Col>
                }
            </Row>
            <Row><Col className="row-bottom-spacer">&nbsp;</Col></Row>
        </Container>
    );
}

export default ProductGroupImageList;