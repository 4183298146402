import {Col, Container, Form, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loggedInUser} from "../user/userSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {shops} from "../shops/shopSlice";
import "./userprofile.css"

const UserProfile = () => {
    const user = useSelector(loggedInUser)
    const dispatch = useDispatch()
    const [email, setEmail] = useState()
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const axios = useAxiosPrivate()
    const availableShops = useSelector(shops)

    useEffect(() => {
        if (user) {
            setEmail(user.email)
            setFirstName(user.first_name)
            setLastName((user.last_name))
        }
    }, []);


    return (<Container className="mt-4">
        <Form className="userprofile-form">
            <Row>
                <Col md={4} sm={0}></Col>
                <Col md={4}>
                    <label className="form-label"><strong>Email</strong></label>
                    <div>{user?user.email:""}</div>

                </Col>
                <Col md={4} sm={0}></Col>
            </Row>
            <Row>&nbsp;</Row>

            <Row>
                <Col md={4} sm={0}></Col>
                <Col md={4}>
                    <label className="form-label"><strong>Naam</strong></label>
                    <div>{user?user.first_name:""} {user?user.last_name:""}</div>
                </Col>
                <Col md={4} sm={0}></Col>
            </Row>
            <Row>&nbsp;</Row>

            <Row>
                <Col md={4} sm={0}></Col>
                <Col md={4}>
                    <label className="form-label"><strong>Organisatie</strong></label>
                    <div>{user && user.customer?user.customer.name:""}</div>
                </Col>
                <Col md={4} sm={0}></Col>
            </Row>
            <Row>&nbsp;</Row>
            <Row>
                <Col md={4} sm={0}></Col>
                <Col md={4}>
                    <label className="form-label"><strong>Bestel rechten voor winkels</strong></label>
                    {availableShops ? availableShops.map(shop => {
                        return <div>{shop.name}</div>
                    }) : <div>Geen rechten</div>}

                </Col>
                <Col md={4} sm={0}></Col>
            </Row>

            <Row>&nbsp;</Row>

            <Row>
                <Col md={4} sm={0}></Col>
                <Col md={4}>
                    <label className="form-label"><strong>Supervisor voor organisatie {user && user.customer?user.customer.name:""} </strong></label>
                    <div>{user?user.is_supervisor?"Ja":"Nee":""}</div>

                </Col>
                <Col md={4} sm={0}></Col>
            </Row>
            <Row>&nbsp;</Row>

        </Form>

    </Container>)
}

export default UserProfile