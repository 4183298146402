import React, {useEffect, useState, useTransition} from "react";
import "./ProductItems.css";
import {Col, Row, Spinner} from "react-bootstrap";
import ProductItem from "./ProductItem";
import {useSelector} from "react-redux";
import {productItems, productItemsLoading} from "./productItemSlice";
import {descriptionFilter, productGroupFilter, selectedProductGroup} from "../productgroups/productGroupSlice";
import {basketItems} from "../basketitems/basketItemsSlice";
import {stockList} from "../stock/stockSlice";
import ReactPaginate from "react-paginate";
import DeviceIdentifier from 'react-device-identifier';


const ProductItemList = (props) => {
    const [items, setItems] = useState([]);

    const selectedGroupFromState = useSelector(selectedProductGroup);
    const pgFilter = useSelector(productGroupFilter);
    const searchTermFilter = useSelector(descriptionFilter);
    const allitemsFromState = useSelector(productItems);
    const basketItemsFromState = useSelector(basketItems);
    const stockListFromState = useSelector(stockList)
    const [pagedProductItems, setpagedProductItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(15)
    const itemsLoading = useSelector(productItemsLoading)
    const [isPending, startTransition] = useTransition()
    const [selectedPage, setSelectedPage] = useState(0);

    const getSearchTerm = (pgFilter, searchTerm) => {
        if (pgFilter === "Nr.") {
            return ('Nr. ' + searchTerm).toLowerCase()
        } else if (pgFilter === "Naamblok ") {
            return ('Naamblok ' + searchTerm).toLowerCase()
        } else if (pgFilter === "Letter") {
            return ('Letter ' + searchTerm).toLowerCase()
        } else {
            return searchTerm.toLowerCase()
        }
    }

    let isMounted = true;
    useEffect(() => {
        startTransition(() => {
            let filteredItems = allitemsFromState;
            if (selectedGroupFromState > 0) {
                filteredItems = allitemsFromState.filter(productItem =>
                    productItem.product_group === parseInt(selectedGroupFromState)
                )
            }

            if (pgFilter && pgFilter !== "0") {
                filteredItems = filteredItems.filter(productItem => productItem.description.includes(pgFilter))
            }

            if (searchTermFilter && searchTermFilter !== "") {
                filteredItems = filteredItems.filter(productItem => productItem.description.toLowerCase().includes(getSearchTerm(pgFilter, searchTermFilter.toLowerCase())))
            }

            setItems(filteredItems)
        })


        return () => {
            isMounted = false;
        }
    }, [basketItemsFromState, allitemsFromState, selectedGroupFromState, stockListFromState, pgFilter, searchTermFilter]);

    useEffect(() => {
        setItemOffset(0)
        setSelectedPage(0)
    }, [selectedGroupFromState]);


    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        setpagedProductItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, items, basketItemsFromState]);


    const stockForArticle = (article_nr) => {
        if (stockListFromState && stockListFromState.length > 0) {
            let stockItem = stockListFromState.find(item => decodeURIComponent(item.artnr) === article_nr)
            if (stockItem) {
                if (Number.isInteger(stockItem.vvoor)) {
                    return stockItem.vvoor <= 0 ? "In productie" : stockItem.vvoor
                } else {
                    return stockItem.vvoor
                }
            }
            return null;
        }
        return null;

    }

    const findTotalInBasket = (productItemId) => {
        if (basketItemsFromState) {
            let foundBasketItem = basketItemsFromState.find(item => item.product_item === productItemId)
            if (foundBasketItem)
                return foundBasketItem.quantity
            else
                return 0
        } else {
            return 0
        }
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setSelectedPage(event.selected)
        setItemOffset(newOffset);
    };

    return (
        <>
            <DeviceIdentifier isDesktop={true}>
                <div className="list-items">
                    <Row className="productitems-header">
                        <Col md={2} className="productitems-header-col">&nbsp;</Col>
                        <Col md={5} className="text-left productitems-header-col"><strong>Artikel</strong></Col>
                        <Col md={2} className="text-center"><strong>Voorraad</strong></Col>
                        <Col md={2} className="text-center"><strong>Aantal</strong></Col>
                        <Col md={1} className="text-center">Winkelwagen</Col>
                    </Row>

                    {!itemsLoading ? pagedProductItems.length ? pagedProductItems.map(productItem => {
                        return <ProductItem key={productItem.id} productitem={productItem}
                                            totalInBasket={findTotalInBasket(productItem.id)}
                                            stock={stockForArticle(productItem.article_nr)}/>
                    }) : <Row>
                        <Col md={12} className="text-center align-self-center align-items-center "> Geen producten
                            beschikbaar.</Col>
                    </Row> : <Row>
                        <Col colSpan="5" className="text-center"><Spinner animation="border"
                                                                          role="status"></Spinner></Col>
                    </Row>}
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col md={12} className="text-right pagination-col">

                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="volgende >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="< vorige"
                                renderOnZeroPageCount={null}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                forcePage={selectedPage}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />

                        </Col>
                    </Row>
                    <Row><Col className="row-bottom-spacer">&nbsp;</Col></Row>
                </div>
            </DeviceIdentifier>
            <DeviceIdentifier isMobile={true} isTablet={true}>
                <div>

                    {pagedProductItems.length ? pagedProductItems.map(productItem => {
                        return <ProductItem key={productItem.id} productitem={productItem}
                                            totalInBasket={findTotalInBasket(productItem.id)}
                                            stock={stockForArticle(productItem.article_nr)}/>
                    }) : <Row><Col md={12}>Geen items beschikbaar</Col></Row>}
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col md={12} className="text-right">
                            <div id="container">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName={'pagination'}
                                    forcePage={selectedPage}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row><Col className="row-bottom-spacer">&nbsp;</Col></Row>
                </div>
            </DeviceIdentifier>
        </>
    );
}

export default ProductItemList;