import {Button, Col, Container, Form, Row} from "react-bootstrap";

import {useDispatch, useSelector} from "react-redux";
import {passwordError, passwordResetSuccess, resetPasswordConfirm} from "./userProfileSlice";
import {useEffect, useState} from "react";

import {useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";


const ResetPasswordConfirm = ({props}) => {

    const dispatch = useDispatch()
    const {uid, token} = useParams()
    const [new_password, setNew_password] = useState("");
    const [re_new_password, setRe_new_password] = useState("");
    const passError = useSelector(passwordError)
    const resetSuccess = useSelector(passwordResetSuccess)
    const location = useLocation()
    const [displayedError, setDisplayedError] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
            if (resetSuccess) {
               navigate('/login', {state: {from: location}, replace: true})
            }
        }, [resetSuccess]
    )

    useEffect(() => {
        if (passError && passError.length > 0) {
            let errorString = ""
            for (const idx in passError) {
                errorString += passError[idx] + "   "
            }
            setDisplayedError(errorString)
        } else {
            setDisplayedError("")
        }
    }, [passError]);



    const handleSubmit = e => {
        e.preventDefault()
        const confirmBody = {uid: uid, token: token, new_password: new_password, re_new_password:re_new_password}
        dispatch(resetPasswordConfirm({axios, confirmBody}))
    }
    return (
        <Container className="mt-4">
            <Form>
                <Row>
                    <Col md={4} sm={0}></Col>
                    <Col md={4}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label><strong>Nieuw wachtwoord</strong></Form.Label>
                            <Form.Control id="newPassword" type="password" placeholder="Nieuw wachtwoord..."
                                          onChange={e => setNew_password(e.target.value)} value={new_password}/>
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={0}></Col>
                </Row>
                <Row>
                    <Col md={4} sm={0}></Col>
                    <Col md={4}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label><strong>Nieuw wachtwoord opnieuw</strong></Form.Label>
                            <Form.Control id="reNewPassword" type="password" placeholder="Nieuw wachtwoord opnieuw..."
                                          onChange={e => setRe_new_password(e.target.value)}
                                          value={re_new_password}/>
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={0}></Col> </Row>


                {/*</Row>*/}
                <Row>
                    <Col md={4} sm={0}></Col>
                    <Col md={4} sm={0} className="pass_error">{displayedError} </Col>
                    <Col md={4} sm={0}></Col>
                    </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col md={4} sm={0}></Col>
                    <Col md={4} className="text-center align-self-center">
                        <Button onClick={handleSubmit}>Nieuw wachtwoord opslaan</Button>
                    </Col>
                    <Col md={4} sm={0}></Col>
                </Row>

            </Form>

        </Container>
    )

}

export default ResetPasswordConfirm