import {useNavigate} from "react-router-dom"
import {Col, Container, Row} from "react-bootstrap";

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <Container className="mt-4">
            <Row><Col md={12}>Verboden</Col></Row>
            <Row><Col md={12}>U heeft geen toegang tot deze pagina</Col></Row>
            <Row><Col md={12}> <button onClick={goBack}>Terug</button></Col></Row>
        </Container>
    )
}

export default Unauthorized
