import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    ADD_ITEM_TO_BASKET,
    DELETE_ITEM_FROM_BASKET,
    dispatchMessage,
    ERROR,
    INFO,
    LIST_BASKET_ITEMS,
    POST_ORDER,
    SUCCESS,
    UPDATE_BASKET_ITEM
} from "../message/messageCodes";
import {setMessage} from "../message/messageSlice";
import {updateStockInfo} from "../stock/stockSlice";
import {setSelectedProductGroup} from "../productgroups/productGroupSlice";

const initialState = {
    basketItems: [],
    orderPosted: 0,
    changedBasketItems: []
};


export const addProductItemToBasket = createAsyncThunk('addProductItemToBasket', async ({
                                                                                            axios,
                                                                                            basketItem,
                                                                                            productitem
                                                                                        }, thunkAPI) => {
    try {
        const response = await axios.post("/basketitems/", basketItem)
        await dispatchMessage(thunkAPI, null, ADD_ITEM_TO_BASKET, "Product: " + productitem.description + " toegevoegd aan de winkelwagen", SUCCESS)
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, ADD_ITEM_TO_BASKET, "Er is iets fout gegaan op de server, probeert u het later opnieuw", ERROR)
    }
})

export const deleteProductItemFromBasket = createAsyncThunk('deleteProductItemFromBasket', async ({
                                                                                                      axios,
                                                                                                      basketItemId,
                                                                                                      productItem
                                                                                                  }, thunkAPI) => {
    try {
        const response = await axios.delete("/basketitems/" + basketItemId)
        await dispatchMessage(thunkAPI, null, DELETE_ITEM_FROM_BASKET, "Product: " + productItem.description + " verwijderd uit de winkelwagen", INFO)
        thunkAPI.dispatch(listBasketItems(axios))
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, DELETE_ITEM_FROM_BASKET, "Er is iets fout gegaan op de server, probeert u het later opnieuw", ERROR)
    }
})

export const listBasketItems = createAsyncThunk('listBasketItems', async (axios, thunkAPI) => {
    try {
        const response = await axios.get("/basketitems/");
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, LIST_BASKET_ITEMS, "Er is iets fout gegaan op de server, probeert u het later opnieuw", ERROR)
    }
})

export const postBasket = createAsyncThunk('postBasket', async ({axios, basketRefInfo}, thunkAPI) => {
    try {
        const response = await axios.post("/orders/", basketRefInfo);
        await dispatchMessage(thunkAPI, null, POST_ORDER, "Uw order is succesvol verwerkt", SUCCESS)
        let orderId = response.data.id
        thunkAPI.dispatch(updateStockInfo({axios, orderId}))
        thunkAPI.dispatch(setSelectedProductGroup(-1))
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, POST_ORDER, "Er is iets fout gegaan tijdens het plaatsen van een order, probeert u het later opnieuw", ERROR)
    }
})

export const updateBasketItem = createAsyncThunk('updateBasketItem', async ({axios, item}, thunkAPI) => {
    try {
        const response = await axios.put(`/basketitems/${item.id}/`, item)
        return response.data
    } catch (error) {
        let message = {
            code: UPDATE_BASKET_ITEM,
            message: "Er is iets fout gegaan op de server, probeert u het later opnieuw",
            messageType: ERROR
        }
        console.error(error)
        await thunkAPI.dispatch(setMessage(message))
        throw error
    }
})


export const basketItemsSlice = createSlice({
    name: "basketItems",
    initialState,
    reducers: {
        setBasketItemChanged(state, action) {
            const found = state.changedBasketItems.find(element => element.basketItemId === action.payload.basketItemId);
            if (!found) {
                return {
                    ...state,
                    changedBasketItems: [...state.changedBasketItems, action.payload]
                }
            } else {
                const updatedChangedItems = state.changedBasketItems.map(item => {
                    if (item.basketItemId === action.payload.basketItemId) {
                        return {...item, ...action.payload};
                    }
                    return item;
                });
                return {
                    ...state,
                    changedBasketItems: updatedChangedItems
                }
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(listBasketItems.fulfilled, (state, action) => {
                state.basketItems = action.payload;
            })
            .addCase(listBasketItems.rejected, (state, action) => {
                console.log(action.error.message)
            })
            .addCase(addProductItemToBasket.fulfilled, (state, action) => {
                if (state.basketItems) {
                    const found = state.basketItems.find(element => element.product_item === action.payload.product_item);
                    if (!found) {
                        return {
                            ...state,
                            basketItems: [...state.basketItems, action.payload]
                        }
                    } else {
                        const updatedBasketItems = state.basketItems.map(item => {
                            if (item.id === action.payload.id) {
                                return {...item, ...action.payload};
                            }
                            return item;
                        });
                        return {
                            ...state,
                            basketItems: updatedBasketItems
                        }
                    }
                }
            })
            .addCase(addProductItemToBasket.rejected, (state, action) => {
                console.log(action.error.message)
            })
            .addCase(postBasket.fulfilled, (state, action) => {
                state.orderPosted = state.orderPosted + 1;
            })
            .addCase(postBasket.rejected, (state, action) => {
                console.log(action.error.message)
            })
            .addCase(updateBasketItem.fulfilled, (state, action) => {
                const updatedBasketItems = state.basketItems.map(item => {
                    if (item.id === action.payload.id) {
                        return {...item, ...action.payload};
                    }
                    return item;
                });
                return {
                    ...state,
                    basketItems: updatedBasketItems
                };
            })
            .addCase(updateBasketItem.rejected, (state, action) => {
                console.log(action.error.message)
            }).addCase(deleteProductItemFromBasket.fulfilled, (state, action) => {
            // const idx = state.basketItems.findIndex(element => element.id === action.payload.basketItemId);
            // return [...state.basketItems.slice(0, idx), ...state.basketItems.slice(idx + 1)]

        })
            .addCase(deleteProductItemFromBasket.rejected, (state, action) => {
                console.log(action.error.message)
            })
    }
})

export const basketItems = (state) => state.basketItems.basketItems
export const orderPosted = (state) => state.basketItems.orderPosted
export const changedBasketItems = (state) => state.basketItems.changedBasketItems
export const {setBasketItemChanged} = basketItemsSlice.actions
export const basketItemsReducer = basketItemsSlice.reducer;
