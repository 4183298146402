// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components

import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

import Moment from 'moment';
import {BACKORDER, DELIVERED, IN_PROGRESS, NOT_AVAILABLE} from "./common";


const PrintOrder = React.forwardRef((props, ref) => {
    const {order, itemStatuses, orderItems, orderStatus} = props

    function formatDate(created_at) {
        Moment.locale('nl');
        return Moment(created_at).format('D-MM-YY')
    }

    const findStatus = (articleNumber) => {
        if (itemStatuses) {
            const orderItem = itemStatuses.find(statusItem => statusItem.artnr === articleNumber)
            if (orderItem) {
                if (orderItem.ehbac > 0) {
                    return BACKORDER
                } else if (orderItem.aaneh === orderItem.gelev) {
                    return DELIVERED
                } else {
                    return IN_PROGRESS
                }
            }
        }
        return DELIVERED
    }


    const getOrderItemsForOrder = (orderId) => {
        return orderItems.filter(item => item.order === orderId)
    }


    return (
        <div ref={ref} key={"ref-" + order.id} className="mt-4">
            {<Container key={"ref-" + order.id + "container-print"}>
                <Row key={"ref-" + order.id + "header-print"} className="order-header">
                    <Col xs={1} className="text-center align-self-center"><strong>OrderId</strong></Col>
                    <Col xs={2} className="text-center align-self-center"><strong>Besteldatum</strong></Col>
                    <Col xs={2} className="text-center align-self-center">Gewenste leverdatum</Col>
                    <Col xs={2} className="text-center align-self-center"><strong>Geplaatst door</strong></Col>
                    <Col xs={2} className="text-center align-self-center"><strong>Winkel</strong></Col>
                    <Col xs={3} className="text-center align-self-center"><strong>Status</strong></Col>

                </Row>


                <Row className="mt-1 order-expanded" key={"ref-" + order.id + "row-data-print-"}>
                    <Col key={1} xs={1} className="text-center align-self-center">{order.order_id}</Col>
                    <Col xs={2} key={2}
                         className="text-center align-self-center">{formatDate(order.created_at)}</Col>
                    <Col xs={2} key={3}
                         className="text-center align-self-center">{formatDate(order.delivery_date)}</Col>
                    <Col xs={2} key={4}
                         className="text-center align-self-center">{order.user.first_name} {order.user.last_name}</Col>
                    <Col xs={2} key={5} className="text-center align-self-center">{order.shop.name}</Col>
                    <Col xs={3} key={6}
                         className="text-center align-self-center">{orderStatus}</Col>

                </Row>
                {order.description?
                <Row key={"ref-" + order.id + "order-item-header-print-1"} className="order-expanded-description">
                    <Col xs={2} className="text-left align-self-top">Bestel informatie</Col>
                    <Col xs={8} className="text-left align-self-top">{order.description}</Col>
                    <Col xs={2} className="text-left align-self-center">&nbsp;</Col>
                </Row>:<></>}


                <div className="list-items" key={"ref-" + order.id + "div"}>
                    {
                        orderItems && orderItems.length ? orderItems.map(orderItem => {
                                return <>
                                    <Row className="list-row-print order-item-print"
                                         key={"ref-" + order.id + "row-expanded-print-" + orderItem.id}>
                                        <Col xs={2}
                                             className="text-center align-self-center">{orderItem.product_item.article_nr}</Col>
                                        <Col xs={6}
                                             className="text-left align-self-center">{orderItem.product_item.description}</Col>
                                        <Col xs={2} className="text-center align-self-center">{orderItem.quantity}</Col>
                                        <Col xs={2}
                                             className="text-left align-self-center">{findStatus(orderItem.product_item.article_nr)}</Col>
                                    </Row>
                                    <div className="page-break" key={"ref-" + order.id + "-page-break-" + orderItem.id}/>

                                </>
                            }
                        ) : <></>

                    }
                </div>

            </Container>
            }

        </div>
    );
});

export default PrintOrder