import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import './index.css';
import App from './App';
import {AuthProvider} from './context/AuthProvider';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {store} from "./app/store";
import {Provider} from "react-redux";
import {createRoot} from "react-dom/client";


createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/*" element={<Provider store={store}><App/></Provider>}/>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>,
);