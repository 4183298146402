import {configureStore} from "@reduxjs/toolkit";
import {productGroupReducer} from "../components/productgroups/productGroupSlice";
import {userReducer} from "../components/user/userSlice";
import {productItemReducer} from "../components/productitems/productItemSlice";
import {basketItemsReducer} from "../components/basketitems/basketItemsSlice"
import {stockReducer} from "../components/stock/stockSlice";
import {orderReducer} from "../components/overview/orderSlice";
import {shopReducer} from "../components/shops/shopSlice";
import {messageReducer} from "../components/message/messageSlice";
import {userProfileReducer} from "../components/userprofile/userProfileSlice";

export const store = configureStore({
    reducer: {
        productGroups: productGroupReducer,
        user: userReducer,
        basketItems: basketItemsReducer,
        productItems: productItemReducer,
        stock: stockReducer,
        orders: orderReducer,
        shops: shopReducer,
        messages: messageReducer,
        userProfile: userProfileReducer,
    },
})