import React, {useEffect, useState} from "react";
import "./orders.css";
import OrderItems from "./OrderItems";
import {useDispatch, useSelector} from "react-redux";
import Moment from 'moment';
import {getOrderItems, getOrderItemsStatus, getOrders, orderItems, orderItemStatus, orders} from "./orderSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {orderPosted} from "../basketitems/basketItemsSlice";
import {Button, Col, Container, Row} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import DeviceIdentifier from 'react-device-identifier';
import ReactToPrint from "react-to-print";
import PrintOrder from "./PrintOrder";
import {DELIVERED, NOT_AVAILABLE, translateStatus} from "./common";


const OrderOverview = (props) => {
    const [selectedOrder, setSelectedOrder] = useState(null);
    const ordersFromState = useSelector(orders);
    const orderItemsFromState = useSelector(orderItems);
    const dispatch = useDispatch()
    const axios = useAxiosPrivate()
    const orderPostedFromState = useSelector(orderPosted)
    const [pagedOrders, setpagedOrders] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(15)
    const orderStatusList = useSelector(orderItemStatus)
    const [selectedOrderIds, setSelectedOrderIds] = useState([])
    const printRefs = React.useRef([]);

    let isMounted = true;

    useEffect(() => {
        dispatch(getOrders(axios))
    }, [orderPostedFromState])

    useEffect(() => {
        // dispatch(getOrderItems({axios, selectedOrder}))
    }, [selectedOrder])

    useEffect(() => {
        if (ordersFromState) {
            const endOffset = itemOffset + itemsPerPage;
            setpagedOrders(ordersFromState.slice(itemOffset, endOffset));

            setPageCount(Math.ceil(ordersFromState.length / itemsPerPage));
        }
        return () => {
            isMounted = false;
        }
    }, [ordersFromState, itemOffset])

    useEffect(() => {
        if (pagedOrders && pagedOrders.length > 0) {
            const orderIds = pagedOrders.map(order => order.id).join()
            setSelectedOrderIds(orderIds)

            printRefs.current = printRefs.current.slice(0, pagedOrders.length);

        }
    }, [pagedOrders]);

    useEffect(() => {
        if (selectedOrderIds && selectedOrderIds.length > 0) {
            dispatch(getOrderItems({axios, selectedOrderIds}))
            dispatch(getOrderItemsStatus({axios, selectedOrderIds}))
        }
    }, [selectedOrderIds, itemOffset]);


    const getOrderItemsForOrder = (orderId) => {
        return orderItemsFromState.filter(item => item.order === orderId)
    }

    const getOrderItemsStatusses = (orderId) => {
        if (orderStatusList && orderStatusList.length > 0) {
            const orderStatus = orderStatusList.find(orderElement => orderElement.ordnr === orderId);

            if (orderStatus) {
                return orderStatus.orderlines;
            }

            return []

        }

    }

    const getStatusForOrder = (orderId) => {
        if (orderStatusList) {
            const orderStatus = orderStatusList.find(orderElement => orderElement.ordnr === orderId);

            if (orderStatus) {
                return translateStatus(orderStatus.statu);
            }
            return DELIVERED
        }
    }


    const handleRowClick = (event, orderId) => {
        if (event.target.nodeName !== "BUTTON") {//react to print is propagating onclick to row onl=click
            selectedOrder === null ? setSelectedOrder(orderId) : setSelectedOrder(null)
        }
    }

    const renderOrderItems = (order) => {
        let itemRows = []
        if (orderItemsFromState && selectedOrder && selectedOrder === order.id) {
            return <OrderItems key={"overview-order-item" + order.id} order={order}
                               orderItems={getOrderItemsForOrder(order.id)}
                               orderStatus={getStatusForOrder(order.order_id)}
                               itemStatuses={getOrderItemsStatusses(order.order_id)}/>
        }
        return itemRows;
    }

    function formatDate(created_at) {
        Moment.locale('nl');
        return Moment(created_at).format('D-MM-YY')
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % ordersFromState.length;
        setItemOffset(newOffset);
    };

    return (

        <Container className="mt-4">
            <DeviceIdentifier key="device-d" isDesktop={true} isTablet={true}>
                {pagedOrders.length > 0 ?
                    <Row key="header" className="order-header">
                        <Col md={1} className="text-center align-self-center"><strong>OrderID</strong></Col>
                        <Col md={1} className="text-left align-self-center"><strong>Besteldatum</strong></Col>
                        <Col md={1} className="text-center align-self-center date-custom-width">Gewenste datum</Col>
                        <Col md={3} className="text-center align-self-center"><strong>Geplaatst door</strong></Col>
                        <Col md={3} className="text-center align-self-center"><strong>Winkel</strong></Col>
                        <Col md={2} className="text-center align-self-center"><strong>Status</strong></Col>
                        <Col md={1} className="text-left align-self-center">&nbsp;
                        </Col>
                    </Row> :  <Row key={"not-available"}>
                            <Col md={12} className="text-center align-self-center align-items-center "> Geen orders
                                beschikbaar.</Col>
                        </Row>}
                <div>
                    {pagedOrders ? pagedOrders.map((order, i) => {
                        return <React.Fragment key={i}>
                            <Row className={selectedOrder === order.id ? "mt-1 order-expanded" : "mt-1 order"}
                                 key={"row-data-" + order.id} onClick={(event) => handleRowClick(event, order.id)}
                            >
                                <Col key={1} md={1}
                                     className="text-center align-self-center">{order.order_id}</Col>
                                <Col md={1} key={2}
                                     className="text-center align-self-center">{formatDate(order.created_at)}</Col>
                                <Col md={1} key={3}
                                     className="text-center align-self-center">{formatDate(order.delivery_date)}</Col>

                                <Col md={3} key={4}
                                     className="text-center align-self-center">{order.user.first_name} {order.user.last_name}</Col>
                                <Col md={3} key={5}
                                     className="text-center align-self-center">{order.shop.name}</Col>
                                <Col md={2} key={6}
                                     className="text-center align-self-center">{getStatusForOrder(order.order_id)}</Col>
                                <Col md={1} key={7} className="text-left align-self-center">

                                    <ReactToPrint
                                        trigger={() => <Button>Print</Button>}
                                        content={() => printRefs.current[i]}
                                    />
                                    <div style={{display: "none"}}>
                                        <PrintOrder order={order} orderItems={getOrderItemsForOrder(order.id)}
                                                    orderStatus={getStatusForOrder(order.order_id)}
                                                    itemStatuses={getOrderItemsStatusses(order.order_id)}
                                                    ref={(order) => printRefs.current[i] = order}/>
                                    </div>
                                </Col>
                            </Row>
                            {renderOrderItems(order)}
                            <Row className="order-bottom" key={"order-bottom-" + order.id}>&nbsp;</Row>
                        </React.Fragment>

                    }) : <>
                        <Row key={"not-available"}>
                            <Col md={10} className="text-center align-self-center align-items-center "> Geen orders
                                beschikbaar.</Col>
                        </Row>
                    </>}
                    <Row key="spacer-1">&nbsp;</Row>
                    <Row key="pagination">
                        <Col md={12} className="text-right">
                            <div id="container">
                                <ReactPaginate breakLabel="..." nextLabel="volgende >" onPageChange={handlePageClick}
                                               pageRangeDisplayed={5} pageCount={pageCount}
                                               previousLabel="< vorige" renderOnZeroPageCount={false}
                                               pageClassName="page-item" pageLinkClassName="page-link"
                                               previousClassName="page-item" previousLinkClassName="page-link"
                                               nextClassName="page-item" nextLinkClassName="page-link"
                                               breakClassName="page-item" breakLinkClassName="page-link"
                                               containerClassName={'pagination'}
                                               subContainerClassName={'pages pagination'} activeClassName={'active'}/>
                            </div>
                        </Col>
                    </Row>
                    <Row><Col className="row-bottom-spacer">&nbsp;</Col></Row>
                </div>
            </DeviceIdentifier>
            <DeviceIdentifier key="device-m" isMobile={true}>

                <div>
                    {pagedOrders ? pagedOrders.map((order, i) => {
                        return <React.Fragment key={i}>
                            <div key={"overview-item-" + order.id} onClick={(event) => handleRowClick(event, order.id)}
                                 className={selectedOrder === order.id ? "mt-1 order-expanded" : "mt-1 order"}>
                                <Row>
                                    <Col><strong>OrderId</strong></Col>
                                    <Col className="text-center align-self-center">{order.order_id}</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Besteldatum</strong></Col>
                                    <Col className="text-center align-self-center">{formatDate(order.created_at)}</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Leverdatum</strong></Col>
                                    <Col
                                        className="text-center align-self-center">{formatDate(order.delivery_date)}</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Geplaatst door</strong></Col>
                                    <Col
                                        className="text-center align-self-center">{order.user.first_name} {order.user.last_name}</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Winkel</strong></Col>
                                    <Col className="text-center align-self-center">{order.shop.name}</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Status</strong></Col>
                                    <Col
                                        className="text-center align-self-center">{getStatusForOrder(order.order_id)}</Col>
                                </Row>

                            </div>
                            {renderOrderItems(order)}
                            <Row className="order-bottom-mobile" key={"order-bottom-" + order.id}>&nbsp;</Row>
                        </React.Fragment>

                    }) : <>
                        <Row>
                            <Col md={10} className="text-center align-self-center align-items-center "> Geen orders
                                beschikbaar.</Col>
                        </Row>
                    </>}
                    <Row key="spacer-1">&nbsp;</Row>
                    <Row key="pagination">
                        <Col md={12} className="text-right">
                            <div id="container">
                                <ReactPaginate breakLabel="..." nextLabel="volgende >" onPageChange={handlePageClick}
                                               pageRangeDisplayed={5} pageCount={pageCount}
                                               previousLabel="< vorige" renderOnZeroPageCount={false}
                                               pageClassName="page-item" pageLinkClassName="page-link"
                                               previousClassName="page-item" previousLinkClassName="page-link"
                                               nextClassName="page-item" nextLinkClassName="page-link"
                                               breakClassName="page-item" breakLinkClassName="page-link"
                                               containerClassName={'pagination'}
                                               subContainerClassName={'pages pagination'} activeClassName={'active'}/>
                            </div>
                        </Col>
                    </Row>
                    <Row><Col className="row-bottom-spacer">&nbsp;</Col></Row>
                </div>
            </DeviceIdentifier>
        </Container>

    );
}


export default OrderOverview;