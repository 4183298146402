import React from "react";
import {Col, Image, Row} from "react-bootstrap";
import DeviceIdentifier from 'react-device-identifier';
import {BACKORDER, DELIVERED, IN_PROGRESS} from "./common";


const OrderItems = (props) => {
    const {orderItems, order, itemStatuses, orderStatus} = props


    const findStatus = (articleNumber) => {
        if (itemStatuses) {
            const orderItem = itemStatuses.find(statusItem => statusItem.artnr === articleNumber)
            if (orderItem) {
                if (orderItem.ehbac > 0) {
                    return BACKORDER
                } else if (orderItem.aaneh === orderItem.gelev) {
                    return DELIVERED
                } else {
                    return IN_PROGRESS
                }
            }
        }
        return DELIVERED
    }

    return (

        <>

            {order.description ?
                <Row key="order-item-header-1" className="order-expanded-description ">
                    <Col md={2} className="text-center align-self-top ibs-bold">Extra informatie</Col>
                    <Col md={8} className="text-left align-self-top">{order.description}</Col>
                    <Col md={2} className="text-left align-self-center">&nbsp;</Col>
                </Row> : <></>}

            <DeviceIdentifier isDesktop={true} isTablet={true}>
                {/*<Row key="order-item-header-2" className="order-item-header">*/}
                {/*    <Col md={1} className="text-center align-self-center align-items-center">&nbsp;</Col>*/}
                {/*    <Col md={1} className="text-center align-self-center align-items-center">*/}
                {/*        <strong>Afbeelding</strong></Col>*/}
                {/*    <Col md={2} className="text-center align-self-center"><strong>Artikelnummer</strong></Col>*/}
                {/*    <Col md={5} className="text-left align-self-center"><strong>Naam</strong></Col>*/}
                {/*    <Col md={1} className="text-center align-self-center"><strong>Aantal</strong></Col>*/}
                {/*    <Col md={2} className="text-left align-self-center">Status</Col>*/}
                {/*</Row>*/}
                <div className="list-items">
                    {
                        orderItems.length ? orderItems.map((orderItem, i) => {
                                return <React.Fragment key={i}>
                                    <Row className="list-row order-item" key={"row-expanded-" + orderItem.id}>
                                        <Col md={1}
                                             className="text-center align-self-center align-items-center">&nbsp;</Col>
                                        <Col md={1} className="text-center align-self-center align-items-center">
                                            <Image src={orderItem.product_item.image} className="item-image"
                                                   onError={({currentTarget}) => {
                                                       currentTarget.onerror = null; // prevents looping
                                                       currentTarget.src = "img/404.png";
                                                   }}/>
                                        </Col>
                                        <Col md={2}
                                             className="text-center align-self-center">{orderItem.product_item.article_nr}</Col>
                                        <Col md={5}
                                             className="text-left align-self-center">{orderItem.product_item.description}</Col>
                                        <Col md={1} className="text-center align-self-center">{orderItem.quantity}</Col>
                                        <Col md={2}
                                             className="text-left align-self-center">{findStatus(orderItem.product_item.article_nr)}</Col>
                                    </Row>
                                    <Row key={"order-item-bottom" + orderItem.id}
                                         className="order-item-bottom-row">&nbsp;</Row>
                                </React.Fragment>
                            }
                        ) : <></>

                    }
                </div>
            </DeviceIdentifier>
            <DeviceIdentifier isMobile={true}>
                <div className="list-items">
                    {
                        orderItems.length ? orderItems.map((orderItem, i) => {
                                return <React.Fragment key={i}>
                                    <Row className="order-item-image align-self-center align-items-center"
                                         key={"row-expanded-2"}><Col><strong>Afbeelding</strong></Col>
                                        <Col>
                                            <Image src={orderItem.product_item.image} className="item-image"
                                                   onError={({currentTarget}) => {
                                                       currentTarget.onerror = null; // prevents looping
                                                       currentTarget.src = "img/404.png";
                                                   }}/>
                                        </Col></Row>
                                    <Row key={"row-expanded-3"}
                                         className="order-item-mobile align-self-center align-items-center">
                                        <Col><strong>Artikelnummer</strong></Col><Col
                                    >{orderItem.product_item.article_nr}</Col></Row>
                                    <Row key={"row-expanded-4"}
                                         className="order-item-mobile align-self-center align-items-center"><Col><strong>Naam</strong></Col>
                                        <Col>{orderItem.product_item.description}</Col></Row>
                                    <Row key={"row-expanded-5"}
                                         className="order-item-mobile align-self-center align-items-center"><Col><strong>Aantal</strong></Col><Col>{orderItem.quantity}</Col></Row>
                                    <Row key={"row-expanded-6"}
                                         className="order-item-mobile align-self-center align-items-center"><Col><strong>Status</strong></Col><Col>{findStatus(orderItem.product_item.article_nr)}</Col></Row>

                                    <Row key={"order-item-bottom-mobile" + orderItem.id}
                                         className="order-item-bottom-row-mobile">&nbsp;</Row>
                                </React.Fragment>
                            }
                        ) : <></>

                    }
                </div>
            </DeviceIdentifier>
        </>

    )

}


export default OrderItems;