import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {
    dispatchMessage, ERROR,
    GET_UPDATED_STOCK,
    INFO,
    RESET_PASSWORD_CONFIRM,
    RESET_PASSWORD_REQUEST,
    SAVE_ME,
    SUCCESS
} from "../message/messageCodes";
import {BASE_URL} from "../../api/axios";


const initialState = {
    passwordResetSuccess: false,
    passwordError: []
};


export const resetPasswordRequest = createAsyncThunk('resetPassWordRequest', async ({axios, emailBody}, thunkAPI) => {
    try {
        const response = await axios.post(BASE_URL + `/users/reset_password/`, emailBody)
        await dispatchMessage(thunkAPI, null, RESET_PASSWORD_REQUEST, "Een mail is verstuurd naar: " + emailBody.email + " als deze bij ons bekend is.", SUCCESS)
        return response.data
    } catch (error) {

        await dispatchMessage(thunkAPI, error, GET_UPDATED_STOCK, "Er is iets fout gegaan op de server, probeert u het later opnieuw", INFO)

    }
})

export const saveMe = createAsyncThunk('saveMe', async ({axios, body}, thunkAPI) => {
    try {
        const response = await axios.put(BASE_URL + `/users/me/`, body)
        await dispatchMessage(thunkAPI, null, SAVE_ME, "Gegevens zijn opgeslagen", SUCCESS)
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, SAVE_ME, "Er is iets fout gegaan op de server, probeert u het later opnieuw", INFO)
    }
})

export const resetPasswordConfirm = createAsyncThunk('resetPassWordConfirm', async ({
                                                                                        axios,
                                                                                        confirmBody
                                                                                    }, thunkAPI) => {
    try {
        const response = await axios.post(BASE_URL + `/users/reset_password_confirm/`, confirmBody)
        await dispatchMessage(thunkAPI, null, RESET_PASSWORD_CONFIRM, "Uw wachtwoord is succesvol gewijzigd", SUCCESS)
        return thunkAPI.fulfillWithValue(response.data)
    } catch (error) {
        await dispatchMessage(thunkAPI, null, RESET_PASSWORD_CONFIRM, "Wachtwoord wijzigen mislukt, ", ERROR)
        return thunkAPI.rejectWithValue(error)
    }
})


export const userProfileSlice = createSlice({
    name: "userProfile",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(resetPasswordRequest.fulfilled, (state, action) => {
                console.log(action.payload)
            })
            .addCase(resetPasswordRequest.rejected, (state, action) => {
                console.log(action.payload)
            })
            .addCase(resetPasswordConfirm.fulfilled, (state, action) => {
                state.passwordResetSuccess = true
                state.passwordError = []
            })
            .addCase(resetPasswordConfirm.rejected, (state, action) => {
                state.passwordError = action.payload.response.data.new_password
                state.passwordResetSuccess = false
            })
            .addCase(saveMe.fulfilled, (state, action) => {
                console.log(action.payload)
            })
            .addCase(saveMe.rejected, (state, action) => {
                console.log(action.payload)
            })
    }
})
export const passwordError = (state) => state.userProfile?.passwordError
export const passwordResetSuccess = (state) => state.userProfile?.passwordResetSuccess
export const userProfileReducer = userProfileSlice.reducer;