import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {BASE_URL} from "../../api/axios";

import {dispatchMessage, GET_UPDATED_STOCK, INFO, LIST_STOCK_INFO, UPDATE_STOCK_INFO} from "../message/messageCodes";


const initialState = {
    stockList: []
};

export const listStockInfo = createAsyncThunk('listStockInfo', async ({axios, articles, id}, thunkAPI) => {
    try {
        const response = await axios.get(BASE_URL + "/stock?articleNumbers=" + articles, {timeout: 60000});
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, LIST_STOCK_INFO, "Er is iets fout gegaan tijdens het ophalen van de voorraad, de voorraad is niet beschikbaar, maar u kunt gewoon doorgaan met bestellen", INFO, id)
        return thunkAPI.rejectWithValue(articles)
    }
})

export const updateStockInfo = createAsyncThunk('updateStockInfo', async ({axios, orderId}, thunkAPI) => {
    try {
        const response = await axios.get(BASE_URL + "/stock/order/" + orderId, {timeout: 60000});
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, UPDATE_STOCK_INFO, "Er is iets fout gegaan tijdens het ophalen van de voorraad, de voorraad is niet beschikbaar, maar u kunt gewoon doorgaan met bestellen", INFO)
    }
})

export const getUpdatedStock = createAsyncThunk('getUpdatedStock', async ({axios, articleNr}, thunkAPI) => {
    try {
        const response = await axios.get(`/api/v1/stock?articleNumbers=${articleNr}`)
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, GET_UPDATED_STOCK, "Er is iets fout gegaan tijdens het ophalen van de voorraad, de voorraad is niet beschikbaar, maar u kunt gewoon doorgaan met bestellen", INFO)
    }
})


export const stockSlice = createSlice({
    name: "stockList",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(listStockInfo.fulfilled, (state, action) => {
                if (action.payload !== undefined)
                    state.stockList = state.stockList.concat(action.payload)
            })
            .addCase(listStockInfo.rejected, (state, action) => {
                let result = []
                for (const idx in action.payload) {
                    const item = {};
                    item.artnr = action.payload[idx];
                    item.vvoor = "Niet beschikbaar"
                    result.push(item)
                }

                if (action.payload !== undefined)
                    state.stockList = state.stockList.concat(result)

            })
            .addCase(getUpdatedStock.fulfilled, (state, action) => {
                const updatedStockList = state.stockList.map(item => {
                    if (item.artnr === action.payload.artnr) {
                        return {...item, ...action.payload};
                    }
                    return item;
                });
                return {
                    ...state,
                    stockList: updatedStockList
                };

            })
            .addCase(getUpdatedStock.rejected, (state, action) => {
                console.log(action.error.message)
            })
            .addCase(updateStockInfo.fulfilled, (state, action) => {

                const updatedStockList = state.stockList.map(item => {
                    try {
                        let foundItem = action.payload.find(responseItem => decodeURIComponent(responseItem.artnr) === item.artnr)
                        if (foundItem) {
                            return {...item, ...foundItem};
                        } else {
                            return item
                        }
                    } catch (ValueError) {
                        return item;
                    }
                });
                return {
                    ...state,
                    stockList: updatedStockList
                };

            })
            .addCase(updateStockInfo.rejected, (state, action) => {
                console.log(action.error.message)
            })
    }
})
export const stockList = (state) => state.stock.stockList

export const stockReducer = stockSlice.reducer;