import {setMessage} from "./messageSlice";

export const LIST_STOCK_INFO = "LIST_STOCK_INFO"
export const POST_ORDER = "POST_ORDER"
export const ADD_ITEM_TO_BASKET = "ADD_ITEM_TO_BASKET";
export const LIST_BASKET_ITEMS = "LIST_BASKET_ITEMS"
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDER_ITEMS = "GET_ORDER_ITEMS"
export const GET_ORDER_ITEMS_STATUS = "GET_ORDER_ITEMS_STATUS"
export const UPDATE_BASKET_ITEM = "UPDATE_BASKET_ITEM"
export const GET_PRODUCT_GROUPS = "GET_PRODUCT_GROUPS"
export const GET_SHOPS = "GET_SHOPS"
export const GET_UPDATED_STOCK = "GET_UPDATED_STOCK"
export const GET_LOGGED_IN_USER = "GET_LOGGED_IN_USER"
export const LIST_PRODUCT_ITEMS = "LIST_PRODUCT_ITEMS"
export const BASKET_ITEM_ADDED = "BASKET_ITEM_ADDED"
export const UPDATE_STOCK_INFO = "UPDATE_STOCK_INFO"
export const DELETE_ITEM_FROM_BASKET = "DELETE_ITEM_FROM_BASKET"
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_CONFIRM = "RESET_PASSWORD_CONFIRM"
export const SAVE_ME = "SAVE_ME"


export const ERROR = "ERROR"
export const SUCCESS = "SUCCESS"
export const INFO = "INFO"

export const clearedMessage = {
    code: ERROR,
    message: null,
    messageType: null,
    messageId: null
}

export const dispatchSimpleErrorMessage = (dispatch, text) => {
    const message = {
        code: "",
        message: text,
        messageType: ERROR
    }
    dispatch(setMessage(message))
}

export const dispatchMessage = async (thunkAPI, error, code, message, type, id) => {
    message = id ? {
        code: code,
        message: message,
        messageType: type,
        messageId: id
    } : {
        code: code,
        message: message,
        messageType: type
    }
    await thunkAPI.dispatch(setMessage(message))
    if (error) {
        console.error(error)
        throw error
    }
}





