import React, {useEffect, useState} from "react";
import {Button, Col, Image, Row} from "react-bootstrap";
import "./basketitems.css"
import {useDispatch} from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {deleteProductItemFromBasket, setBasketItemChanged, updateBasketItem} from "./basketItemsSlice";
import NumericInput from "react-numeric-input";
import {dispatchSimpleErrorMessage} from "../message/messageCodes";
import DeviceIdentifier from 'react-device-identifier';


const BasketItem = (props) => {
    const {basketItem, productItem} = props;
    const dispatch = useDispatch()
    const axios = useAxiosPrivate()

    const [quantity, setQuantity] = useState(basketItem.quantity)
    const changeQuantity = (event) => {
        setQuantity(event)
    }

    const deleteFromBasket = () => {
        let basketItemId = basketItem.id
        dispatch(deleteProductItemFromBasket({axios, basketItemId, productItem}))
    }

    const saveQuantity = () => {
        if (!Number.isInteger(quantity)) {
            dispatchSimpleErrorMessage(dispatch, "Het aantal is geen geldig nummer")
            return
        }
        if (quantity % 25 !== 0) {
            dispatchSimpleErrorMessage(dispatch, "Het aantal moet een veelvoud van 25 zijn.")
            return
        }

        let item = {...basketItem, quantity}
        dispatch(updateBasketItem({axios, item}));
    }

    useEffect(() => {
        if (basketItem) {
            setQuantity(basketItem.quantity)
        }
    }, []);

    useEffect(() => {
            if (basketItem) {
                const changedItem = basketItem.quantity !== quantity ?
                    {
                        basketItemId: basketItem.id,
                        changed: true
                    } : {
                        basketItemId: basketItem.id,
                        changed: false
                    }
                dispatch(setBasketItemChanged(changedItem))
            }
        }, [quantity, basketItem]
    )


    return (
        <>
            <DeviceIdentifier isDesktop={true} >
                <Row className="list-row">
                    <Col md={2} xs={3} className="text-center align-self-center align-items-center ">
                        <Image className="item-image"
                               src={"articles/" + productItem.article_nr + ".svg"} rounded/>

                    </Col>
                    <Col md={5} xs={6}
                         className="text-left align-self-center align-items-center ">{productItem.description}</Col>
                    <Col md={2} xs={3} className="text-center align-self-center spinnner-col align-items-center">
                        <NumericInput
                            mobile
                            className="form-control"
                            value={quantity}
                            min={0}
                            max={1000000}
                            step={25}
                            precision={0}
                            size={5}
                            onChange={changeQuantity}
                        />

                    </Col>
                    <Col md={2} xs={9} className="text-right align-self-center align-items-center" >
                        <Button  onClick={deleteFromBasket}>
                            Verwijder
                        </Button>
                    </Col>
                    <Col md={1} xs={3} className="text-right align-self-center align-items-center" >
                        <Button onClick={saveQuantity} disabled={basketItem.quantity === quantity}>Opslaan</Button>
                    </Col>

                </Row>
                <Row key={"basket-item-bottom" + basketItem.id} className="basket-item-bottom-row">&nbsp;</Row>
            </DeviceIdentifier>
            <DeviceIdentifier isMobile={true} isTablet={true}>
                <div>
                    <Row className="basket-item-top-row-mobile">
                        <Col xs={12}>&nbsp;</Col>
                    </Row>
                    <Row className="basket-item-row-mobile">
                        <Col xs={3}
                             className="text-left align-self-top align-items-top"><strong>Afbeelding</strong></Col>
                        <Col xs={9} className="text-center align-self-top align-items-center"><Image
                            className="item-image"
                            src={"articles/" + productItem.article_nr + ".svg"} rounded/>
                        </Col>
                    </Row>
                    <Row className="basket-item-row-mobile">
                        <Col xs={3}
                             className="text-left align-self-top align-items-top"><strong>Bestelling</strong></Col>
                        <Col xs={9}
                             className="text-right align-self-top align-items-top">{productItem.description}</Col>
                    </Row>
                    <Row className="basket-item-row-mobile">
                        <Col xs={3}
                             className="text-left align-self-center align-items-top"><strong>Aantal</strong></Col>
                        <Col xs={3}>&nbsp;</Col>
                        <Col xs={6} className="text-left align-self-center align-items-top">
                            <NumericInput
                                mobile
                                className="form-control"
                                value={quantity}
                                min={0}
                                max={1000000}
                                step={25}
                                precision={0}
                                size={5}
                                onChange={changeQuantity}
                            /></Col>
                    </Row>
                    <Row className="basket-item-row-mobile">

                        <Col xs={6} ><Button onClick={deleteFromBasket}>
                            Verwijderen
                        </Button></Col>
                        <Col className="text-right"  xs={6}><Button onClick={saveQuantity}
                                            disabled={basketItem.quantity === quantity}>Opslaan</Button></Col>

                    </Row>
                    <Row className="basket-item-bottom-row-mobile">
                        <Col xs={12}>&nbsp;</Col>
                    </Row>
                </div>
            </DeviceIdentifier>
        </>
    )
        ;

}


export default BasketItem
