import axios from 'axios';
import isDev from "../common/env";

export const BASE_URL = isDev()?'http://localhost:8000/api/v1':'https://api.ibs-bestelservice.nl/api/v1';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosStock = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

const MAX_REQUESTS_COUNT = 1
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

/**
 * Axios Request Interceptor
 */
axiosStock.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})
/**
 * Axios Response Interceptor
 */
axiosStock.interceptors.response.use(function (response) {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
  return Promise.resolve(response)
}, function (error) {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
  return Promise.reject(error)
})
