import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {dispatchMessage, ERROR, LIST_PRODUCT_ITEMS} from "../message/messageCodes";
import {listStockInfo} from "../stock/stockSlice";
import {v4 as uuidv4} from "uuid";

const initialState = {
    productItems: [],
    loaded: false,
    articleNumbers: [],
    loading: true
};

export const listProductItems = createAsyncThunk('productItems', async (axios, thunkAPI) => {
    let id = uuidv4()
    try {
        const response = await axios.get("/productitems/");
        let productItems = response.data
        let articleNumbers = productItems.map(a => a.article_nr)
        const chunkSize = 50;
        for (let i = 0; i < articleNumbers.length; i += chunkSize) {
            const articles = articleNumbers.slice(i, i + chunkSize);
            thunkAPI.dispatch(listStockInfo({axios, articles, id}))
        }
        return {productItems, articleNumbers}
    } catch (error) {
        await dispatchMessage(thunkAPI, error, LIST_PRODUCT_ITEMS, "Er is iets fout gegaan op de server, probeert u het later opnieuw", ERROR)
    }
})


export const productItemSlice = createSlice({
    name: "productItems",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(listProductItems.fulfilled, (state, action) => {
                state.productItems = action.payload.productItems;
                state.articleNumbers = action.payload.articleNumbers;
                state.loading = false
            })
            .addCase(listProductItems.rejected, (state, action) => {
                console.log(action.error.message)
            })
    }
})
export const productItems = (state) => state.productItems.productItems
export const productItemsLoading = (state) => state.productItems.loading
export const productItemReducer = productItemSlice.reducer;