import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {BASE_URL} from "../../api/axios";
import axios from "axios";
import {dispatchMessage, ERROR, GET_LOGGED_IN_USER} from "../message/messageCodes";
import {getCookie} from "../../common/security";


const initialState = {
    user: null
};

var csrftoken = getCookie('csrftoken');


export const getLoggedInUser = createAsyncThunk('getLoggedInUser', async (token, thunkAPI) => {
    try {
        const response = await axios.get(BASE_URL + "/users/me/", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT ' + token,
                    'X-CSRFToken': csrftoken}
            }
        );

        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, GET_LOGGED_IN_USER, "Er is iets fout gegaan op de server, probeert u het later opnieuw", ERROR)
    }
})


export const userSlice = createSlice({
    name: "loggedInUser",
    initialState,
    reducers: {
        logoutUser(state, action) {
            state.user = null
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getLoggedInUser.fulfilled, (state, action) => {
                state.user = action.payload;
            })
            .addCase(getLoggedInUser.rejected, (state, action) => {
                console.log(action.error.message)
            })
    }
})
export const loggedInUser = (state) => state.user.user

export const {logoutUser} = userSlice.actions
export const userReducer = userSlice.reducer;