import React, {useEffect} from "react";
import {Badge, Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {productGroups, setSearching, setSelectedProductGroup} from "../productgroups/productGroupSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {basketItems} from "../basketitems/basketItemsSlice";
import useLogout from "../../hooks/useLogout";
import {loggedInUser} from "../user/userSlice";
import "./menu.css"

const Menu = (props) => {
    const productGroupList = useSelector(productGroups);
    const basketItemsFromState = useSelector(basketItems);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()
    const logout = useLogout();
    const user = useSelector(loggedInUser)


    const signOut = async () => {
        await logout();

    }

    // useEffect(() => {
    //     if (!user){
    //         navigate('/');
    //     }
    // }, [user]);


    const selectProductGroup = (productGroup) => {
        dispatch(setSelectedProductGroup(productGroup));
        navigate('/seasons', {state: {from: location}, replace: true})
    }

    const goHome = () => {
        dispatch(setSelectedProductGroup(-1));
        dispatch(setSearching(false))
        navigate('/', {state: {from: location}, replace: true})
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand ahref="#" onClick={() => goHome()} className="nav-brand">
                    <img src="/img/bestelservice.png" className="d-inline-block brand-img"/>

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    {user ?
                        <Nav className="ml-auto">
                            <NavDropdown title="COLLECTIES" id="collasible-nav-dropdown"
                                         className={location ? location.pathname.includes("seasons") ? "active-link nav-dropdown" : "nav-dropdown" : "nav-dropdown"}>

                                <NavDropdown.Item key={`menu_item_all_collections`}
                                                             onClick={() => goHome()}>
                                        Alle collecties
                                    </NavDropdown.Item>
                                {productGroupList ? productGroupList.map(productgroup => {
                                    return <NavDropdown.Item key={`menu_item_${productgroup.id}`}
                                                             onClick={() => selectProductGroup(productgroup.id)}>
                                        {productgroup.name}
                                    </NavDropdown.Item>

                                }) : <></>}
                            </NavDropdown>

                            <NavDropdown title="ACCOUNT" id="collasible-nav-dropdown"
                                         className={location ? location.pathname.includes("seasons") ? "active-link nav-dropdown" : "nav-dropdown" : "nav-dropdown"}>
                                <NavDropdown.Item key={`menu_item_overview`}
                                                  onClick={() => navigate('/orders', {
                                                      state: {from: location},
                                                      replace: true
                                                  })}>Overzicht</NavDropdown.Item>
                                {/*<NavDropdown.Item key={`menu_item_profile`}*/}
                                {/*                  className={location ? location.pathname.includes("order") ? "active-link" : "" : ""}*/}
                                {/*                  onClick={() => navigate('/userprofile', {*/}
                                {/*                      state: {from: location},*/}
                                {/*                      replace: true*/}
                                {/*                  })}>Profiel</NavDropdown.Item>*/}
                                <NavDropdown.Item key={`menu_item_logout`}
                                                  className={location ? location.pathname.includes("order") ? "active-link" : "" : ""}
                                                  onClick={signOut}>Uitloggen</NavDropdown.Item>
                            </NavDropdown>

                        </Nav> : <></>}
                    {user ?

                        <Nav>

                            <Button variant="primary" className="menu-basket-button"
                                    disabled={basketItemsFromState && basketItemsFromState.length === 0}
                                    onClick={() => navigate('/basket', {
                                        state: {from: location},
                                        replace: true
                                    })}>
                                WINKELWAGEN <Badge
                                bg="white" text="dark">{basketItemsFromState ? basketItemsFromState.length : 0}</Badge>
                            </Button>

                        </Nav> : <></>}
                    {!user ? <Nav className="mr-auto"></Nav> : <></>}


                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}


export default Menu