import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {dispatchMessage, ERROR, GET_PRODUCT_GROUPS} from "../message/messageCodes";

const initialState = {
    productGroups: [],
    selectedProductGroup: -1,
    productGroupFilter: null,
    descriptionFilter: null,
    loading: true,
    searching: false
};

export const listProductGroups = createAsyncThunk('productGroups', async ({axios}, thunkAPI) => {
    try {
        const response = await axios.get("/productgroups/");
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, GET_PRODUCT_GROUPS, "Er is iets fout gegaan op de server, probeert u het later opnieuw", ERROR)
    }
})


export const productGroupSlice = createSlice({
    name: "productGroups",
    initialState,
    reducers: {
        setSelectedProductGroup(state, action) {
            state.selectedProductGroup = parseInt(action.payload)

        },
        setSearching(state, action) {
            state.searching = action.payload

        },
        setProductGroupFilter(state, action) {
            if (action.payload === "") {
                state.productGroupFilter = null
            } else {
                state.productGroupFilter = action.payload
            }
        },
        setDescriptionFilter(state, action) {
            if (action.payload === "") {
                state.descriptionFilter = null
            } else {
                state.descriptionFilter = action.payload
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(listProductGroups.fulfilled, (state, action) => {
                state.productGroups = action.payload;
                state.loading = false

            })
            .addCase(listProductGroups.rejected, (state, action) => {
                console.log(action.error.message)
                state.loading = false
            })
    }
})
export const productGroups = (state) => state.productGroups.productGroups
export const selectedProductGroup = (state) => state.productGroups.selectedProductGroup
export const productGroupFilter = (state) => state.productGroups.productGroupFilter
export const productGroupsLoading = (state) => state.productGroups.loading
export const searchingFromState = (state) => state.productGroups.searching

export const descriptionFilter = (state) => state.productGroups.descriptionFilter
export const {setSelectedProductGroup, setProductGroupFilter, setDescriptionFilter, setSearching} = productGroupSlice.actions


export const productGroupReducer = productGroupSlice.reducer;