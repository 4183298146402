import {Outlet} from "react-router-dom";
import {useEffect, useState} from "react";
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';
import useLocalStorage from "../hooks/useLocalStorage";
import {Col, Container, Row, Spinner} from "react-bootstrap";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const {auth} = useAuth();
    const [persist] = useLocalStorage('persist', false);

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                console.log(err);
            } finally {
                isMounted && setIsLoading(false);
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, [])

    return (
        <>
            {!persist
                ? <Outlet/>
                : isLoading
                    ? <Container className="mt-4"><Row><Col md={12} xs={12}>   <Spinner animation="border" variant="success" /> </Col></Row></Container>
                    : <Outlet/>
            }
        </>
    )
}

export default PersistLogin