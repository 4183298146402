import {useEffect, useRef, useState} from 'react';
import useAuth from '../../hooks/useAuth';
import {useLocation, useNavigate} from 'react-router-dom';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';
import "./login.css"
import axios from '../../api/axios';
import {ROLES} from "../../App";
import {useDispatch} from "react-redux";
import {getLoggedInUser} from "../user/userSlice";
import {Button, Col, Container, Form, Row} from "react-bootstrap";


const LOGIN_URL = '/token/';

const Login = () => {
    const {setAuth} = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const dispatch = useDispatch()

    const userRef = useRef();
    const errRef = useRef();

    const [user, resetUser, userAttribs] = useInput('user', '')
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const resetPasswordRequestMail = () => {
        navigate('/reset_password', {state: {from: location}, replace: true})
    }

    function mapToRoles(elem) {
        if (elem.name === "ADMIN")
            return ROLES.ADMIN;
        else if (elem.name === "SUPERVISOR")
            return ROLES.SUPERVISOR;
        else if (elem.name === "EMPLOYEE")
            return ROLES.EMPLOYEE;
        return ROLES.EMPLOYEE
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({'email': user, 'password': pwd}),
                {
                    headers: {'Content-Type': 'application/json',
                    },
                    withCredentials: true
                }
            );
            const accessToken = response?.data?.access;

            const roles = [ROLES.EMPLOYEE]
            setAuth({user, pwd, roles, accessToken});
            resetUser();
            setPwd('');
            dispatch(getLoggedInUser(accessToken))
            navigate("/", from, {replace: true});
            setValidated(true);

        } catch (err) {
            if (!err?.response) {
                setErrMsg('De server reageert ninet');
            } else if (err.response?.status === 400) {
                setErrMsg('Geen email of wachtwoord ingevoerd');
            } else if (err.response?.status === 401) {
                setErrMsg('De credentials zijn onjuist');
            } else {
                setErrMsg('Login mislukt');
            }
            errRef.current.focus();
        }
    }

    return (
        <Container Style="margin-top: 80px;" >
            <div>
                <Row className="text-center align-self-center align-items-center login-title">
                    <Col xs={1} md={3}></Col>
                    <Col xs={10} md={6}>
                        <h3><strong>GEBRUIKER LOGIN</strong></h3>
                    </Col>
                    <Col xs={1} md={3}></Col>
                </Row>
                <Row>
                    <Col xs={1} md={3}></Col>
                    <Col xs={10} md={6}>


                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control type="email" ref={userRef} autoComplete="off" {...userAttribs} required/>
                                <Form.Control.Feedback type="invalid">
                                    Voer een geldig email adres in...
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Wachtwoord</Form.Label>
                                <Form.Control type="password" onChange={(e) => setPwd(e.target.value)} value={pwd}
                                              required/>
                                <Form.Control.Feedback type="invalid">
                                    Voer een wachtwoord in.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}
                               aria-live="assertive">{errMsg}</p>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Ingelogd blijven" onChange={toggleCheck}
                                            checked={check}/>
                            </Form.Group>

                           <div className="text-center align-self-center align-items-center"><Button variant="primary" type="submit">Inloggen</Button></div>


                        </Form>
                    </Col>
                    <Col xs={1} md={3}></Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col xs={1} md={3}></Col>
                    <Col xs={10} md={6} className="text-center align-self-center align-items-center"><a onClick={resetPasswordRequestMail}
                                                                                   href="#">Wachtwoord
                        vergeten?</a></Col>
                    <Col xs={1} md={3}></Col>
                </Row>
                <Row>
                    <Col xs={1} md={3}></Col>
                    <Col xs={10} md={6} className="text-center align-self-center align-items-center"><p>Andere hulp nodig? Neem gerust
                        contact op. Wij zitten voor u klaar!</p></Col>
                    <Col xs={1} md={3}></Col>
                </Row>
            </div>
        </Container>

    )
}

export default Login
