import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ERROR} from "./messageCodes";
import {v4 as uuidv4} from "uuid";


const initialState = {
    messageId: null,
    code: null,
    message: null,
    type: null,
    shown: false
};

export const setMessage = createAsyncThunk('setError', async (message, thunkApi) => {
    try {
        return await thunkApi.fulfillWithValue(message)
    } catch (error) {
        throw error
    }
})


export const messageSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        setMessageShown(state, action) {
            state.shown = true
        }
    },
    extraReducers(builder) {

        builder
            .addCase(setMessage.fulfilled, (state, action) => {
                state.type = action.payload.messageType ? action.payload.messageType : ERROR;
                if (action.payload.message) {
                    const newMessageId = action.payload.messageId ? action.payload.messageId : uuidv4()
                    state.shown = state.messageId === newMessageId
                    state.messageId = newMessageId
                    state.code = action.payload.code
                    state.message = action.payload.message

                } else {
                    state = initialState
                }
            })
            .addCase(setMessage.rejected, (state, action) => {
                state = initialState
            })

    },
})
export const messageCode = (state) => state.messages?.code
export const popupMessage = (state) => state.messages?.message
export const newMessage = (state) => state.messages?.messageId
export const messageType = (state) => state.messages?.type
export const messageShown = (state) => state.messages?.shown
export const {setMessageShown} = messageSlice.actions
export const messageReducer = messageSlice.reducer;