import axios from "../api/axios";
import useAuth from "./useAuth";
import {useDispatch} from "react-redux";
import {logoutUser} from "../components/user/userSlice";
import {selectedProductGroup, setSelectedProductGroup} from "../components/productgroups/productGroupSlice";

const useLogout = () => {
    const {setAuth} = useAuth();
    const dispatch = useDispatch()

    const logout = async () => {

        try {
            dispatch(logoutUser())
            dispatch(setSelectedProductGroup(null))
            setAuth({});
            const response = await axios.delete('/logout', {
                withCredentials: true
            });

        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout