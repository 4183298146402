import React, {useEffect, useState} from "react";
import {Button, Col, Container, Form, FormControl, Row} from "react-bootstrap";
import BasketItem from "./BasketItem";
import {useDispatch, useSelector} from "react-redux";
import {productItems} from "../productitems/productItemSlice";
import {basketItems, changedBasketItems, postBasket} from "./basketItemsSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useLocation, useNavigate} from "react-router-dom";
import {shops} from "../shops/shopSlice";
import DatePicker from "react-datepicker";
import DeviceIdentifier from 'react-device-identifier';
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import {setSelectedProductGroup} from "../productgroups/productGroupSlice";


const BasketItems = () => {

    const basketItemsFromState = useSelector(basketItems);
    const allProductItems = useSelector(productItems)
    const allowedShops = useSelector(shops)
    const dispatch = useDispatch()
    const axios = useAxiosPrivate()
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedShop, setSelectedShop] = useState(null);
    const [description, setDescription] = useState("");
    const basketItemsChanged = useSelector(changedBasketItems)
    const [orderDisabled, setOrderDisabled] = useState(false);
    const [pagedBasketItems, setpagedBasketItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage] = useState(15)


    const date = new Date();
    date.setDate(date.getDate() + 2);


    const [deliveryDate, setDeliveryDate] = useState(date);

    const postOrder = () => {
        setOrderDisabled(true)
        let basketRefInfo = {shop: selectedShop, order_description: description, delivery_date: deliveryDate}
        dispatch(postBasket({axios, basketRefInfo}))
    }

    useEffect(() => {
        if (basketItemsFromState && basketItemsFromState.length === 0) {
            dispatch(setSelectedProductGroup(-1))
            navigate('/', {state: {from: location}, replace: true})
        }
        if (allowedShops && allowedShops.length > 0) {
            setSelectedShop(allowedShops[0].id)
        }
    }, [basketItemsFromState])

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setpagedBasketItems(basketItemsFromState.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(basketItemsFromState.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, basketItemsFromState]);


    const getProductItem = (id) => {
        if (allProductItems) {
            let foundItem = allProductItems.find(item => item.id === id)
            if (foundItem)
                return foundItem
            else
                return {description: "Geen product gevonden"}
        } else
            return {description: "Geen product gevonden"}
    }

    useEffect(() => {
        if (allowedShops && allowedShops.length > 0) {
            setSelectedShop(allowedShops[0].id)
        }
    }, [allowedShops]);

    useEffect(() => {
        if (basketItemsChanged) {
            for (const idx in basketItemsChanged) {
                if (basketItemsChanged[idx].changed) {
                    setOrderDisabled(true)
                    return
                }
            }
            return setOrderDisabled(false)
        }
    }, [basketItemsChanged])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % basketItemsFromState.length;
        setItemOffset(newOffset);
    };


    return (
        <Container className="mt-2">

            {pagedBasketItems && pagedBasketItems.length > 0 ? <>
                    <DeviceIdentifier isDesktop={true} isMobile={false} isTablet={false}>
                        <Row className="basket-items-header">
                            <Col md={2} xs={3}
                                 className="text-center align-self-center align-items-center "><strong>Afbeelding</strong></Col>
                            <Col md={5} xs={6}
                                 className="text-left align-self-center align-items-center "><strong>Bestelling</strong></Col>
                            <Col className="text-center align-self-center align-items-center "
                                 md={2} xs={3}><strong>Aantal</strong></Col>
                            <Col md={1} xs={0}>&nbsp;</Col>
                            <Col md={2} xs={0}>&nbsp;</Col>
                        </Row>

                    </DeviceIdentifier>
                </> :
                <Row>
                    <Col md={12} className="text-center align-self-center align-items-center "> Geen productenn in de
                        winkelwagen</Col>
                </Row>}

            <div className="list-items">
                {pagedBasketItems ? pagedBasketItems.map(basketItem => {
                    return <BasketItem key={basketItem.id} basketItem={basketItem}
                                       productItem={getProductItem(basketItem.product_item)}/>
                }) : <tr></tr>}
            </div>

            <Row>&nbsp;</Row>
            <Row>
                <Col md={12} className="text-right">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />

                </Col>
            </Row>
            {pagedBasketItems && pagedBasketItems.length > 0 ? <>
                <Row className="order-description">
                    <Col md={12} sm={12} className="order-description-right">
                        <Form.Group className="mb-3" controlId="formBasicSelect">
                            <Form.Label className="ibs-bold">Extra informatie</Form.Label>
                            <FormControl   as="textarea" aria-label="With textarea"
                                         onChange={(e) => setDescription(e.target.value)}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} sm={12} className="order-description-left">
                        <Form.Group className="mb-3" controlId="formBasicSelect" >
                            <Form.Label className="ibs-bold">Winkel</Form.Label>
                            <Form.Control  as="select" onChange={(e) => {
                                setSelectedShop(e.target.value);
                            }}>

                                {allowedShops ? allowedShops.map(shop => {
                                    return <option value={shop.id} key={shop.id}>{shop.name}</option>

                                }) : <></>}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={2} sm={12} className="order-description-right">
                        <Form.Group className="mb-3">
                            <Form.Label className="ibs-bold">Gewenste datum</Form.Label>
                            <DatePicker className="form-control" selected={deliveryDate}
                                        onChange={(date) => setDeliveryDate(date)}/>
                        </Form.Group>
                    </Col>
                    <Col md={7} sm={12} className="text-right align-self-bottom align-items-bottom ">
                        <Form.Label className="ibs-bold">&nbsp;</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Label className="ibs-bold">&nbsp;</Form.Label>
                             <Button  disabled={pagedBasketItems && pagedBasketItems.length === 0}
                            onClick={postOrder} disabled={orderDisabled}>Bestellen</Button>
                        </Form.Group>

                </Col>
                </Row>
                <Row className="basket-item-footer-correction">&nbsp;</Row>

                <Row className="basket-item-footer-correction">&nbsp;</Row>
                <Row><Col className="row-bottom-spacer">&nbsp;</Col></Row>


            </> : <></>}


        </Container>
    );
}

export default BasketItems;