import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import "./login.css"
import axios from '../../api/axios';

import {useDispatch} from "react-redux";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {resetPasswordRequest} from "../userprofile/userProfileSlice";

const ResetPassword = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [requestEmail, setRequestEmail] = useState()
    const dispatch = useDispatch()

    const resetPasswordRequestMail = () => {
        let emailBody = {email: requestEmail}
        dispatch(resetPasswordRequest({axios, emailBody}))
        navigate('/login', {state: {from: location}, replace: true})
    }


    return (
         <Container Style="margin-top: 80px;" >

                <Row className="text-center align-self-center align-items-center login-title">
                    <Col xs={1} md={3}></Col>
                    <Col xs={10} md={6}>
                        <h3><strong>Wachtwoord wijziging aanvragen</strong></h3>
                    </Col>
                    <Col xs={1} md={3}></Col>
                </Row>
            <Row>
                <Col xs={1} md={3}></Col>
                <Col xs={10} md={6} className="text-center">
                   Stuur ons uw e-mailadres en u ontvangt binnen enkele minuten een e-mail met een link voor een nieuw wachtwoord.
                </Col>
                <Col xs={1} md={3}></Col>

            </Row>
             <Row>
                <Col >&nbsp;</Col>
             </Row>
            <Row>
                <Col xs={1} md={3}></Col>
                <Col xs={10} md={6}>


                    <Form noValidate >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control type="email" value={requestEmail} onChange={(e) => setRequestEmail(e.target.value)} required/>
                            <Form.Control.Feedback type="invalid" >
                                Voer een geldig email adres in...
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={1} md={3}></Col>
            </Row>
            <Row>&nbsp;</Row>
            <Row>
            <Col xs={1} md={3}></Col>
            <Col xs={10} md={6} className="text-center align-self-center"><Button onClick={resetPasswordRequestMail} href="#">Verzenden</Button></Col>
            <Col xs={1} md={3}></Col>
        </Row>
        </Container>

    )
}

export default ResetPassword
