// import React from "@types/react";
import "./fonts/Raleway-Bold.ttf";
import "./fonts/Raleway-Regular.ttf";
import "./fonts/Raleway-ExtraBold.ttf";


import Login from './components/login/Login';
import Home from './components/Home';
import Layout from './components/Layout';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import {Route, Routes} from 'react-router-dom';
import Menu from "./components/menu/Menu";
import {useDispatch, useSelector} from "react-redux";
import {listProductGroups} from "./components/productgroups/productGroupSlice";
import useAxiosPrivate from "./hooks/useAxiosPrivate";
import {useEffect, useTransition} from "react";
import {listBasketItems, orderPosted} from "./components/basketitems/basketItemsSlice";
import {loggedInUser} from "./components/user/userSlice";
import {listProductItems} from "./components/productitems/productItemSlice";
import BasketItems from "./components/basketitems/BasketItems";
import OrderOverview from "./components/overview/OrderOverview";
import Footer from "./components/footer/Footer";
import {listShops} from "./components/shops/shopSlice";
import UserProfile from "./components/userprofile/UserProfile";
import ToastMessage from "./components/message/ToastMessage";
import useAxiosStock from "./hooks/useAxiosStock";
import ResetPasswordConfirm from "./components/userprofile/ResetPasswordConfirm";
import ResetPassword from "./components/login/ResetPassword";
import Seasons from "./components/seasons";


export const ROLES = {
    'EMPLOYEE': 2001,
    'SUPERVISOR': 1984,
    'ADMIN': 5150
}

const App = (props) => {

    const [isPending, startTransition] = useTransition()

    const axios = useAxiosPrivate()
    const axiosStock = useAxiosStock()

    const dispatch = useDispatch();
    const user = useSelector(loggedInUser)
    const userBasketItems = useSelector(listBasketItems)
    const orderPostedFromState = useSelector(orderPosted)

    useEffect(() => {
        if (user && user.id > 0) {
            dispatch(listProductGroups({axios}))
            dispatch(listProductItems(axiosStock))
            startTransition(() => {
                dispatch(listBasketItems(axios))
                dispatch(listShops(axios))
            })


        }
    }, [user]);

    useEffect(() => {
        if (orderPostedFromState)
            dispatch(listBasketItems(axios))
    }, [orderPostedFromState]);

    return (
        <>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"/>
            <Menu basketItems={userBasketItems}/>
            <ToastMessage/>
            <div>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        {/* public routes */}

                        <Route path="/reset_password_confirm/:uid/:token" element={<ResetPasswordConfirm/>}/>
                        <Route path="/reset_password" element={<ResetPassword/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/unauthorized" element={<Unauthorized/>}/>
                        {/*<Route path="/" element={<Home/>}/>*/}

                        {/* we want to protect these routes */}
                        <Route element={<PersistLogin/>}>
                            <Route element={<RequireAuth allowedRoles={[ROLES.EMPLOYEE]}/>}>
                                <Route path="/" element={<Home/>}/>
                            </Route>

                            <Route element={<RequireAuth allowedRoles={[ROLES.EMPLOYEE]}/>}>
                                <Route path="/seasons" element={<Seasons/>}/>
                            </Route>

                            <Route
                                element={<RequireAuth allowedRoles={[ROLES.EMPLOYEE, ROLES.SUPERVISOR, ROLES.ADMIN]}/>}>
                                <Route path="/basket" element={<BasketItems/>}/>
                            </Route>

                            <Route
                                element={<RequireAuth allowedRoles={[ROLES.EMPLOYEE, ROLES.SUPERVISOR, ROLES.ADMIN]}/>}>
                                <Route path="/orders" element={<OrderOverview/>}/>
                            </Route>

                            <Route
                                element={<RequireAuth allowedRoles={[ROLES.EMPLOYEE, ROLES.SUPERVISOR, ROLES.ADMIN]}/>}>
                                <Route path="/userprofile" element={<UserProfile/>}/>
                            </Route>
                        </Route>


                        {/* catch all */}
                        <Route path="*" element={<Missing/>}/>
                    </Route>
                </Routes>
            </div>
            <Footer/>
        </>
    );
}

export default App;