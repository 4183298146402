import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {dispatchMessage, ERROR, GET_SHOPS} from "../message/messageCodes";

const initialState = {
    shops: []
};

export const listShops = createAsyncThunk('listShops', async (axios, thunkAPI) => {
    try {
        const response = await axios.get("/shops/");
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, GET_SHOPS, "Er is iets fout gegaan op de server, probeert u het later opnieuw", ERROR)
    }
})


export const shopSlice = createSlice({
    name: "shops",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(listShops.fulfilled, (state, action) => {
                state.shops = action.payload;
            })
            .addCase(listShops.rejected, (state, action) => {
                console.log(action.error.message)
            })
    }
})
export const shops = (state) => state.shops.shops

export const shopReducer = shopSlice.reducer;