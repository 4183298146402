import React, {useEffect, useReducer, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import "./Productgroups.css";
import {useDispatch, useSelector} from "react-redux";
import {
    descriptionFilter,
    productGroupFilter,
    productGroups, searchingFromState,
    selectedProductGroup,
    setDescriptionFilter,
    setProductGroupFilter, setSearching,
    setSelectedProductGroup
} from "./productGroupSlice";
import ProductItemList from "../productitems/ProductItemList";
import ProductGroupImageList from "./ProductGroupImageList";

const ProductGroupList = (props) => {
    const productGroupList = useSelector(productGroups);
    const selectedGroupFromState = useSelector(selectedProductGroup);
    const searchText = useSelector(descriptionFilter);
    const pgFilter = useSelector(productGroupFilter)
    const dispatch = useDispatch()
    const [hideProductGroupSelect, setHideProductGroupSelect] = useState(false);
    const searching = useSelector(searchingFromState)
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [productGroupSelectOptions, setProductGroupSelectOptions] = useState( <><option value="0">Productgroep...</option>
                            <option value="Naamblok">Naamblokken</option>
                            <option value="Nr.">Nummers</option>
                            <option value="Letter">Letters</option>
            </>)


    const changePgFilter = (e) => {
        dispatch(setProductGroupFilter(e.target.value));
        dispatch(setDescriptionFilter(null))
    }


    useEffect(() => {
        setHideProductGroupSelect(selectedGroupFromState === 1)
        if (selectedGroupFromState === 11) {
            setProductGroupSelectOptions( <><option value="0">Productgroep...</option>
                            <option value="Naamblok">Naamblokken</option>
                            <option value="Letter">Letters</option>
            </>)
            dispatch(setProductGroupFilter("0"));
        } else if (selectedGroupFromState === 12 || selectedGroupFromState === 13){
            setProductGroupSelectOptions(<option value="Nr.">Nummers</option>)
            dispatch(setProductGroupFilter("Nr."));
        } else {
            setProductGroupSelectOptions( <><option value="0">Productgroep...</option>
                            <option value="Naamblok">Naamblokken</option>
                            <option value="Nr.">Nummers</option>
                            <option value="Letter">Letters</option>
            </>)
             dispatch(setProductGroupFilter("0"));
        }

    }, [selectedGroupFromState])

    useEffect(() => {
        // dispatch(setProductGroupFilter(null))
        forceUpdate();
    }, [selectedGroupFromState, productGroupList, searching]);

    useEffect(() => {
        dispatch(setDescriptionFilter(null))
        forceUpdate();
    }, [pgFilter]);

    const setDescription = (e) => {
        dispatch(setSearching(true))
        dispatch(setDescriptionFilter(e.target.value))
        forceUpdate()
    }


    return (
        <Container className="mt-4">
            <Row >
                <Col md={3} className="search-col1">
                    <Form.Group className="mb-3" controlId="formBasicSelect">
                        <Form.Control as="select" value={selectedGroupFromState} placeholder="Collectie..."
                                      onChange={(e) => {
                                          dispatch(setSelectedProductGroup(e.target.value));
                                      }}>
                            <option value="0" key={`"all_collections"`}>Alle collecties</option>
                            {productGroupList && productGroupList.length > 0 ? productGroupList.map(productgroup => {
                                return <option value={productgroup.id}
                                               key={`${productgroup.id}`}>{productgroup.name}</option>

                            }) : <></>}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={3} hidden={hideProductGroupSelect}>
                    <Form.Group className="mb-3" controlId="formBasicSelect">
                        <Form.Control as="select" value={pgFilter ? pgFilter : "0"} onChange={(e) => {
                            changePgFilter(e);
                        }}>
                            {productGroupSelectOptions}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={6} className="search-col3">
                    <Form.Group className="mb-3" controlId="formBasicText">
                        <div className="d-flex">
                            <Form.Control type="text" value={searchText ? searchText : ""} placeholder="Zoekterm..."
                                          onChange={(e) => setDescription(e)}/>

                        </div>

                    </Form.Group>
                </Col>
            </Row>

            {selectedGroupFromState === -1 && !searching ?
                <ProductGroupImageList/> :
                <ProductItemList/>
            }

        </Container>

    );
}

export default ProductGroupList;