import ProductGroupImageList from "./productgroups/ProductGroupImageList";
import {useSelector} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";
import {loggedInUser} from "./user/userSlice";
import ProductGroupList from "./productgroups/ProductGroupList";
import {selectedProductGroup} from "./productgroups/productGroupSlice";

const Home = () => {

    const user = useSelector(loggedInUser)
    const selectedProductGroupFromState = useSelector(selectedProductGroup)

    return (
        <Container className="lower-container">
            {selectedProductGroupFromState === -1?
        <Row>

            <Col md={12} className="text-center home-welcome">Welkom {user && user.first_name?user.first_name:""} {user && user.last_name?user.last_name:""}</Col>

        </Row>
                :<></>}
        <ProductGroupList/>
            </Container>
    )
}

export default Home
