import ProductGroupList from "./productgroups/ProductGroupList";

const Home = () => {

    return (
        <ProductGroupList/>
    )
}

export default Home
