import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    dispatchMessage,
    ERROR,
    GET_ORDER_ITEMS,
    GET_ORDER_ITEMS_STATUS,
    GET_ORDERS,
    INFO
} from "../message/messageCodes";

const initialState = {
    orders: [],
    orderItems: [],
    orderItemStatus: []
};

export const getOrders = createAsyncThunk('getOrders', async (axios, thunkAPI) => {
    try {
        const response = await axios.get("/orders/");
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, GET_ORDERS, "Er is iets fout gegaan op de server, probeert u het later opnieuw", ERROR)
    }
})


export const getOrderItems = createAsyncThunk('getOrderItems', async ({axios, selectedOrderIds}, thunkAPI) => {
    try {
        const response = await axios.get("/orderitems?orderIds=" + selectedOrderIds);
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, GET_ORDER_ITEMS, "Er is iets fout gegaan op de server, probeert u het later opnieuw", ERROR)
    }
})

export const getOrderItemsStatus = createAsyncThunk('getOrderItemsStatus', async ({
                                                                                      axios,
                                                                                      selectedOrderIds
                                                                                  }, thunkAPI) => {
    try {
        const response = await axios.get("/orders/status/?orderIds=" + selectedOrderIds);
        return response.data
    } catch (error) {
        await dispatchMessage(thunkAPI, error, GET_ORDER_ITEMS_STATUS, "Er is iets fout gegaan tijdens het ophalen van de status, u kunt gewoon doorwerken.", INFO, 1000)
    }
})


export const orderSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getOrders.fulfilled, (state, action) => {
                state.orders = action.payload;
            })
            .addCase(getOrders.rejected, (state, action) => {
                console.log(action.error.message)
            })
            .addCase(getOrderItems.fulfilled, (state, action) => {
                state.orderItems = action.payload;
            })
            .addCase(getOrderItems.rejected, (state, action) => {
                console.log(action.error.message)
            })
            .addCase(getOrderItemsStatus.fulfilled, (state, action) => {
                state.orderItemStatus = action.payload.ordersinfo
            })
            .addCase(getOrderItemsStatus.rejected, (state, action) => {
                console.log(action.error.message)
            })
    }
})
export const orders = (state) => state.orders.orders
export const orderItems = (state) => state.orders.orderItems
export const orderItemStatus = (state) => state.orders.orderItemStatus

export const orderReducer = orderSlice.reducer;